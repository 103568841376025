






























import { defineComponent, PropType } from '@vue/composition-api';
import { IndustryForm } from '@/modules/industry/types';
import KCard from '@/@core/components/other/KCard.vue';
import useLoadIndustryPhoto from '@/modules/industry/helpers/loadIndustryPhoto';
import KText from '@/@core/components/typography/KText.vue';

export default defineComponent({
  components: { KCard, KText },
  name: 'IndustryItem',
  props: {
    industry: {
      type: Object as PropType<IndustryForm>
    }
  },
  setup() {
    //

    return {
      useLoadIndustryPhoto
    };
  }
});
