


















import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { getManyVillage } from '@/@core/services/api-banjarmasinkota';
import { useDistrictStore } from '@/modules/district/services/store';
import { computed, defineComponent, ref, unref, watch } from '@vue/composition-api';

export default defineComponent({
  components: { KComboboxInput },
  name: 'IndustryDistrictFilter',
  props: {
    district: {
        type: String,
        default: ''
    }
  },
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });
    const store = useDistrictStore();
    const isLoading = ref(false)
    const isShowAllDistrict = computed(() => props.district == 'Semua')

    const districtList = computed(() => {
      const districts: any[] = [...store.districts];
      districts.unshift({
        name: 'Semua'
      });

      return districts;
    });
    
    const villages = ref<any>([])
    const initVillages = async () => {
      isLoading.value = true

      const { result, error } = await getManyVillage('Kota Banjarmasin', props.district)
      if (result) villages.value = result
      villages.value.unshift({
        nama_kelurahan_desa: 'Semua'
      });

      isLoading.value = false
    }

    watch(() => props.district, async (district) => {
        if(!district) return
        
        modelValue.value = 'Semua'
        if (unref(isShowAllDistrict)) return 
        
        await initVillages()
    })

    return {
      modelValue,
      districtList,
      villages,
      isLoading,
      isShowAllDistrict
    };
  }
});
