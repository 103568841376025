






































































































































import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';
import Icon from '../../ui/Icon.vue';
import Navigations from '../../ui/Navigations.vue';
import { useClassificationStore } from '@/modules/classification/services/store';
import IndustryItem from '@/modules/industry/ui/IndustryItem.vue';
import { useIndustryStore } from '@/modules/industry/services/store';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { useLoadProductPhoto } from '../../../product/helpers/loadProductPhoto';
import router from '@/router';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import useTableOptions from '@/@core/helpers/table-options';
import { MaybeRef } from '@vueuse/core';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import DistrictFilter from '@/modules/industry/ui/filters/district-filter.vue';
import { useProductStore } from '@/modules/product/services/store';
import VillageFilter from '@/modules/industry/ui/filters/village-filter.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    HelloWorld,
    KCard,
    Icon,
    Navigations,
    IndustryItem,
    KDialog,
    KTextInput,
    KComboboxInput,
    KTableSearch,
    DistrictFilter,
    VillageFilter
  },
  name: 'LandingIndustryPage',
  setup() {
    const showAllKbli = {
      fulltext: 'Semua'
    }

    const classificationStore = useClassificationStore();
    const industryStore = useIndustryStore();

    const showFilter = ref<null | boolean>(null);
    const search = ref("");
    const filter = ref({
      isVerified: 'Sudah',
      district: 'Semua',
      village: 'Semua',
      kbli: ''
    });
    const tableOptions = ref(useTableOptions({itemsPerPage: 12} as any));
    const totalPagination = computed(() => {
      const totalData = industryStore.total
      const itemPerPage = tableOptions.value.itemsPerPage

      return Math.ceil((totalData) / itemPerPage)
    })

    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const showDetailDialog = ref(false);
    const selectedIndustry = ref(null);
    const selectedClassification = ref(null);
    
    const getManyIndustry = async () => {
      isLoading.value = true;

      await industryStore.getManyIndustry({
        filter,
        options: tableOptions,
        search
      });

      setTimeout(() => {
        isLoading.value = false;
      }, 100)
    };

    const init = async () => {
      await classificationStore.getManyClassification(
        useTableOptions()
      );
      await getManyIndustry()
    };

    const getManyClassification = async (
      search?: MaybeRef<string>
    ) => {
      isLoading.value = true;

      try {
        await classificationStore.getManyClassification(
          useTableOptions(),
          search
        );
      } finally {
        isLoading.value = false;
      }
    };

    const selectIndustry = (industry: any) => {
      // selectedIndustry.value = { ...industry };
      // showDetailDialog.value = true;
      router.push(`/industri/${industry.id}`);
    };

    const sortBy = ref('');

    const scrollToTop = () => {
      // @ts-ignore
      document.getElementById('scrolling-techniques-7').scrollIntoView({behavior:'smooth'});
    }

    onMounted(async () => {
      await init();
    });
    
    watch(filter, async (v) => {
      await getManyIndustry()
      tableOptions.value.page = 1
    }, {
      immediate: false,
      deep: true
    });

    watch(tableOptions, async (v) => {
      await getManyIndustry()
      scrollToTop()
    }, {
      immediate: false,
      deep: true
    });
    
    watch(search, async (v) => {
      const isFirstPage = tableOptions.value.page == 1
      if (isFirstPage) return await getManyIndustry()

      tableOptions.value.page = 1
    });

    return {
      classificationStore,
      sortBy,
      industryStore,
      showDetailDialog,
      selectIndustry,
      useLoadProductPhoto,
      selectedIndustry,
      isMobile,
      getManyClassification,
      reRenderCount,
      isLoading,
      selectedClassification,
      search,
      filter,
      tableOptions,
      totalPagination,
      showFilter,
      showAllKbli,
      scrollToTop
    };
  }
});
