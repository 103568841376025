import { loadIndustryFile } from "@/@core/helpers/loader";

export default function useLoadIndustryPhoto(industry: any) {
  const hasPhotos = industry?.photos?.length;

  if (!hasPhotos) return loadIndustryFile('');

  return loadIndustryFile(industry?.photos[0]?.path);
}

export {
    useLoadIndustryPhoto
}
