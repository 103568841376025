

















import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { useDistrictStore } from '@/modules/district/services/store';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KComboboxInput },
  name: 'IndustryDistrictFilter',
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });
    const store = useDistrictStore();

    const districtList = computed(() => {
      const districts: any[] = [...store.districts];
      districts.unshift({
        name: 'Semua'
      });

      return districts;
    });

    return {
      modelValue,
      districtList
    };
  }
});
