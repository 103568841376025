






























































import { isMobile } from '@/@core/helpers/useBreakpoint';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  components: {},
  name: 'LandingNavigations',
  setup() {
    const navigations = [
      {
        text: 'Utama',
        to: '/'
      },
      {
        text: 'Industri',
        to: '/industri'
      },
      {
        text: 'Produk',
        to: '/produk'
      }
    ];

    const showDrawer = ref(false);

    return {
      navigations,
      showDrawer,
      isMobile
    };
  }
});
