import { loadProductFile } from "@/@core/helpers/loader";
import { Product } from "@/modules/industry/types";

export default function useLoadProductPhoto(product: any) {
    const hasPhotos = product?.photos?.length;

    if (!hasPhotos) return loadProductFile('');

    return loadProductFile(product.photos[0].path);
}

export {
    useLoadProductPhoto
}
